import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/css/font.css';
import './assets/css/style.css';
import './assets/css/reset.css';
import './assets/css/common.css';
import './assets/css/mobile.css';
import './assets/lib/slick/slick.css';
import reportWebVitals from './reportWebVitals';
import AboutPage from './components/AboutPage';
import LandingPage from "./components/LandingPage";
import Layout from "./components/layout/Layout";
import ProductPage from "./components/ProductPage";
import CareersPage from "./components/CareersPage";
import ContactPage from "./components/ContactPage";
import BusinessPage from "./components/BusinessPage";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import TextPlugin from "gsap/TextPlugin"

gsap.registerPlugin(ScrollTrigger, TextPlugin);

window.addEventListener("wheel", function (e){
    e.preventDefault();
    }, {passive: false})

const root = ReactDOM.createRoot(
  document.getElementById('root')
);
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <ScrollToTop />
              <Layout>
                  <Routes>
                      <Route path="/" Component={LandingPage} exact/>
                      <Route path="/about" Component={AboutPage} exact/>
                      <Route path="/product" Component={ProductPage} exact/>
                      <Route path="/careers" Component={CareersPage} exact/>
                      <Route path="/contact" Component={ContactPage} exact/>
                      <Route path="/business" Component={BusinessPage} exact/>
                  </Routes>
              </Layout>
      </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();