import React, {useEffect} from "react";
import icoCategory1 from '../assets/img/ico-category1.png';
import icoCategory2 from '../assets/img/ico-category2.png';
import icoCategory3 from '../assets/img/ico-category3.png';
import icoCategory4 from '../assets/img/ico-category4.png';

import { gsap } from "gsap";
import $ from "jquery";
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useLocation} from "react-router-dom";

function LandingPage() {
    const {pathname} = useLocation();
    let partnersList = [...Array(41).keys()].map((num) => ({
        url: require(`../assets/img/partner/${num}.png`),
    }))

    let partners = partnersList.map((value) =>
        <div className="slide ">
            <img src = {value.url} alt={""}/>
        </div>
    )

    useEffect(() => {
        $("header").removeClass("black");
        $("header .gnb img").css("filter", "none");

        $(".category").on("mouseover", function () {
            $(".category").removeClass("on");
            $(".category .txt-wrapper").css("opacity", "0");
            $(this).addClass("on");
            $(this).children(".txt-wrapper").css("opacity", "1");
            $(this).prevAll().css("transform", "translateX(-60%)");
        });

        $(".category").on("mouseleave", function () {
            $(this).removeClass("on");
            $(this).prevAll().css("transform", "translateX(0)");
            $(this).children(".txt-wrapper").css("opacity", "0");

            $(".category.num-1").addClass("on");
            $(".category.num-1").children(".txt-wrapper").css("opacity", "1");
        });
    },[pathname]);

    useEffect(() => {
        const result = "ZETTASOFT";
        gsap.to(".type-title", {
            duration: 2,
            text: result,
            delimiter: " ",
            delay: 1,
            repeat: -1,
            repeatDelay: 1,
            yoyo: true,
            oncomplete: blinkCursor,
        });

        function blinkCursor() {
            $(".cursor").addClass("blink");
        }
    },[pathname]);

    useEffect(() => {
        gsap.set(".why", {
            x: -400,
            opacity: 0,
        });

        gsap.set(".zs", {
            x: 700,
            opacity: 0,
        });

        gsap.to(".why", {
            scrollTrigger: {
                trigger: ".main-wrapper2",
                id: "main-wrapper2",
                start: "top center",
                end: "+=300",
                scrub: true,
            },
            x: 0,
            opacity: 1,
            duration: 4,
        });

        gsap.to(".zs", {
            scrollTrigger: {
                trigger: ".main-wrapper2",

                start: "top center",
                end: "+=300",
                scrub: true,
            },
            x: 0,
            opacity: 1,
            duration: 4,
        });
    });

    useEffect(() => {

        gsap.to(".main-wrapper3", {
            scrollTrigger: {
                trigger: ".main-wrapper3",
                start: "top center",
                end: "+=400",
                scrub: true,
            },
            opacity: 1,
            duration: 2,
        });
        return () => {
            gsap.from(".main-wrapper3 h1 > span", {
                scrollTrigger: {
                    trigger: ".main-wrapper3",
                    start: "top center",
                    end: "+=400",
                    scrub: true,
                },
                y: 60,
                duration: 3,
                stagger: 0.5,
                ease: "power4",
            });

            gsap.from(".main-wrapper3 p > span", {
                scrollTrigger: {
                    trigger: ".main-wrapper3 h1",
                    start: "bottom 70%",
                    end: "+=400",
                    scrub: true,
                },
                y: 40,
                duration: 3,
                stagger: 0.5,
                ease: "power4",
            });
        };
    });

    useEffect(() => {
        const customer_ani = gsap.timeline();

        customer_ani.fromTo(".main-wrapper5 .title h3 > span",
            {y: 60,
            duration: 3,
            stagger: 0.7,
            ease: "power4",}, {
            scrollTrigger: {
                trigger: ".main-wrapper5",
                start: "top center",
                end: "+=400",
                scrub: true,
            },
            y: 0,
            duration: 3,
            stagger: 0.7,
            ease: "power4",
        })
        .fromTo(
                ".slide-r",
            {x:-60},
                {
                    x: 0,
                    duration: 4,
                    ease: "power4",
                },
                "+=3"
            )
        .fromTo(".you",
            {
                opacity:"0",
                duration: 2},
            {
                opacity: "1",
                ease: "power2",
            });
    });

    const settings = {
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 600,
    };

    return (
        <div>
            <video className="main-mov" muted playsInline autoPlay loop>
                <source src={require("../assets/img/main-mov.mp4")} key="mainVod" type="video/mp4" />
            </video>
            <div className="main-wrapper section" id="section1">
                <div className="main-title">
                    <h3>&nbsp;We make Innovations</h3>
                    <h1>
                        [&nbsp;<span className="type-title"></span
                    ><span className="cursor">|</span>&nbsp;]
                    </h1>
                </div>
            </div>
            <div className="main-wrapper2 section" id="section2">
                <img className="grad" src={require(`../assets/img/main-bg2.png`)} alt={""}/>
                <div className="scale-txt">
                    <h1 className="why">WHY</h1>
                    <h1 className="zs">
                        <span className="blue">ZETTA</span>SOFT<span className="mark">?</span>
                    </h1>
                </div>
            </div>
            <div className="main-wrapper3 section" id="section3">
                <h1><span>데이터 비즈니스 전문기업 제타소프트는</span></h1>
                <h1><span>축적된 IT 기술과 경험을 보유한</span></h1>
                <h1><span>데이터 TREND LEADER 기업입니다.</span></h1>
                <p>
                    <span>고객 니즈에 부합하는 차별화된 데이터 컨설팅, 적절한 솔루션 활용,</span>
                </p>
                <p>
                    <span>데이터 분석과 효율적인 데이터 활용을 원하는 모든 기업과 조직을대상으로</span>
                </p>
                <p>
                    <span>환경과 목적에 맞게 최적화된 데이터 전문 서비스를 제공합니다.</span>
                </p>
            </div>

            <div className="main-wrapper4 section" id="section4">
                <div className="category num-1 on">
                    <div className="txt-wrapper">
                        <div className="title">
                            <img src={icoCategory1} alt={""}/>
                            <div className="line"></div>
                            <div className="name">
                                <p>WHAT WE DO?</p>
                                <h1>빅데이터 분석<br/>플랫폼 개발 및<br/>공급 사업</h1>
                            </div>
                        </div>
                        <div className="detail-wrapper">
                            <div>
                                <img src={require(`../assets/img/ico-cloud.png`)} alt={""}/>
                                <p>Private Cloud, Public Cloud 연동</p>
                            </div>
                            <div>
                                <img src={require(`../assets/img/ico-monitor.png`)} alt={""}/>
                                <p>사용자 빅데이터 분석 및 모니터링 환경 제공</p>
                            </div>
                            <div>
                                <img src={require(`../assets/img/ico-cloudera.png`)} alt={""}/>
                                <p>Cloudera, Kubernetes 연동으로 포털 환경 제공</p>
                            </div>
                        </div>
                    </div>
                    <div className="icon">
                        <img src={icoCategory1} alt={""}/>
                    </div>
                </div>
                <div className="category num-2">
                    <div className="txt-wrapper">
                        <div className="title">
                            <img src={icoCategory2} alt={""}/>
                            <div className="line"></div>
                            <div className="name">
                                <p>WHAT WE DO?</p>
                                <h1>
                                    데이터 포털<br/>
                                    서비스 구축 사업
                                </h1>
                            </div>
                        </div>
                        <div className="detail-wrapper">
                            <div>
                                <img src={require(`../assets/img/ico-auth.png`)} alt={""}/>
                                <p>권한별, 그룹별 SSO 인증 기능</p>
                            </div>
                            <div>
                                <img src={require(`../assets/img/ico-widget.png`)} alt={""}/>
                                <p>
                                    Widget 방식의 시각화 및 원천 데이터 다운로드, 운용에 용이한 API
                                    제공
                                </p>
                            </div>
                            <div>
                                <img src={require(`../assets/img/ico-query.png`)} alt={""}/>
                                <p>동적 쿼리를 생성하는 메타구조 데이터 엔진(SQL파서) 제공</p>
                            </div>
                        </div>
                    </div>
                    <div className="icon">
                        <img src={icoCategory2} alt={""}/>
                    </div>
                </div>
                <div className="category num-3">
                    <div className="txt-wrapper">
                        <div className="title">
                            <img src={icoCategory3} alt={""}/>
                            <div className="line"></div>
                            <div className="name">
                                <p>WHAT WE DO?</p>
                                <h1>
                                    BI 및 시각화 시스템<br/>
                                    구축 사업
                                </h1>
                            </div>
                        </div>
                        <div className="detail-wrapper">
                            <div>
                                <img src={require(`../assets/img/ico-inmemory.png`)} alt={""}/>
                                <p>인메모리 기반 고속의 분석환경 제공</p>
                            </div>
                            <div>
                                <img src={require(`../assets/img/ico-dashboard.png`)} alt={""}/>
                                <p>Drag&Drop 방식의 시각화 대시보드 및 보고서 구성</p>
                            </div>
                            <div>
                                <img src={require(`../assets/img/ico-ai.png`)} alt={""}/>
                                <p>
                                    자체 AI 모델 기반의 숨겨진 인사이트를 추천, 의사결정 제언 기능
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="icon">
                        <img src={icoCategory3} alt={""}/>
                    </div>
                </div>
                <div className="category num-4">
                    <div className="txt-wrapper">
                        <div className="title">
                            <img src={icoCategory4} alt={""}/>
                            <div className="line"></div>
                            <div className="name">
                                <p>WHAT WE DO?</p>
                                <h1>
                                    정보시스템 <br/>
                                    DW / DL / DM<br/>
                                    컨설팅 및 구축 사업
                                </h1>
                            </div>
                        </div>
                        <div className="detail-wrapper">
                            <div>
                                <img src={require(`../assets/img/ico-3d.png`)} alt={""}/>
                                <p>3D 시뮬레이션을 통한 데이터 설계 활용</p>
                            </div>
                            <div>
                                <img src={require(`../assets/img/ico-etl.png`)} alt={""}/>
                                <p>ETL 및 Push 방식의 메시지 교환 등으로 성능과 안정성 확보</p>
                            </div>
                            <div>
                                <img src={require(`../assets/img/ico-intelli.png`)} alt={""}/>
                                <p>데이터 활용을 극대화할 수 있는 지능형 분석 체계 제공</p>
                            </div>
                        </div>
                    </div>
                    <div className="icon">
                        <img src={icoCategory4} alt={""}/>
                    </div>
                </div>
            </div>
            <div className="main-wrapper5 section" id="section5">
                <div className="title">
                    <h1>고객사 및 파트너사</h1>
                    <h3><span>ZETTASOFT IS WORKING</span></h3>
                    <h3>
                        <span>WITH<span className="you">YOU</span>
                            <span className="slide-r">A VARIETY OF</span></span>
                    </h3>
                    <h3><span>COMPANIES</span></h3>
                </div>
                <div className="partner-slider">
                    <Slider {...settings}>
                        {partners}
                    </Slider>
                </div>
                <div className="polygon"></div>
            </div>
        </div>
    )
}

export default LandingPage;