import React, {useEffect} from "react";
import icoPd1 from '../assets/img/ico-pd1.png';
import icoPd2 from '../assets/img/ico-pd2.png';
import icoPd3 from '../assets/img/ico-pd3.png';
import imgGradLinke from '../assets/img/img-grad-linke.png';
import icoEmail from '../assets/img/ico-email.png';
import busPolygon from '../assets/img/bus-polygon.png';
import {Link} from "react-router-dom";
import $ from "jquery";

function BusinessPage() {

    useEffect(()=>{
        $("header").addClass("black");
        $("header .gnb img").css("filter", "invert()");

        function productSlide(idx) {
            $(".product").removeClass("on");
            $(".product").eq(idx).addClass("on");
        }

        function slideBtn(idx) {
            $(".slide-btn > a").removeClass("on");
            $(".slide-btn > a").eq(idx).addClass("on");
        }

        $(document).ready(function () {
            let i = 1;
            setInterval(() => {
                i++;
                if (i >= $(".product").length) {
                    i = 1;
                }
                productSlide(i);
            }, 4000);

            let j = 0;
            setInterval(() => {
                j++;
                if (j >= $(".slide-btn > a").length) {
                    j = 0;
                }
                slideBtn(j);
            }, 4000);
        });
    })
    return (
        <div>
            <div className="contact-wrapper2 section" id="section1">
                <div className="title">
                    <div className="sub-title">
                        <img src={imgGradLinke} alt={""}/>
                        <h3>WE MAKE INNOAVTIONS</h3>
                    </div>
                    <div className="main">
                        <h1><span>무한한 도전, 변화</span></h1>
                        <h1><span>그리고&nbsp;</span></h1>
                        <h1 className="blue"><span>혁신</span></h1>
                        <h1><span>을 만듭니다.</span></h1>
                    </div>
                    <div className="email">
                        <img src={icoEmail} alt={""}/>
                        <p>contact@zettasoft.co.kr<span>(사업문의 이메일 접수중)</span></p>
                    </div>
                </div>
                <div className="link-wrapper">
                    <div className="slide-btn">
                        <a className="on" to=""></a>
                        <a to=""></a>
                        <a to=""></a>
                    </div>
                    <img src={busPolygon} alt={""}/>
                    <div className="link">
                        <div className="product swb on">
                            <div className="title">
                                <img src={icoPd1} alt={""}/>
                                <h3>SuperWorkbench 3.0</h3>
                            </div>
                            <Link to="/product"><p>VIEW</p></Link>
                        </div>
                        <div className="product qlik">
                            <div className="title">
                                <img src={icoPd2} alt={""}/>
                                <h3>QlikSense</h3>
                            </div>
                            <Link to="/product"><p>VIEW</p></Link>
                        </div>
                        <div className="product tableau">
                            <div className="title">
                                <img src={icoPd3} alt={""}/>
                                <h3>Tableau</h3>
                            </div>
                            <Link to="/product"><p>VIEW</p></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BusinessPage;