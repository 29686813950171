import React, {useEffect, useState} from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {Link} from "react-router-dom";
import { gsap } from "gsap";
import $ from "jquery";

const { kakao } = window;

gsap.registerPlugin(ScrollTrigger);

function AboutPage() {
    let partnersList1 = [
        require("../assets/img/partner/1.png"),
        require("../assets/img/partner/2.png"),
        require("../assets/img/partner/3.png"),
        require("../assets/img/partner/4.png"),
        require("../assets/img/partner/5.png")
    ];
    let partnersList2 = [
        require("../assets/img/partner/6.png"),
        require("../assets/img/partner/7.png"),
        require("../assets/img/partner/8.png"),
        require("../assets/img/partner/9.png"),
        require("../assets/img/partner/10.png")
    ];
    let partnersList3 = [
        require("../assets/img/partner/11.png"),
        require("../assets/img/partner/12.png"),
        require("../assets/img/partner/13.png"),
        require("../assets/img/partner/14.png"),
        require("../assets/img/partner/15.png")
    ];
    let partnersList4 = [
        require("../assets/img/partner/16.png"),
        require("../assets/img/partner/17.png"),
        require("../assets/img/partner/18.png"),
        require("../assets/img/partner/19.png"),
        require("../assets/img/partner/20.png")
    ];
    let partnersList5 = [
        require("../assets/img/partner/21.png"),
        require("../assets/img/partner/22.png"),
        require("../assets/img/partner/23.png"),
        require("../assets/img/partner/24.png"),
        require("../assets/img/partner/25.png")
    ];
    let partnersList6 = [
        require("../assets/img/partner/26.png"),
        require("../assets/img/partner/27.png"),
        require("../assets/img/partner/28.png"),
        require("../assets/img/partner/29.png"),
        require("../assets/img/partner/30.png")
    ];
    let partnersList7 = [
        require("../assets/img/partner/31.png"),
        require("../assets/img/partner/32.png"),
        require("../assets/img/partner/33.png"),
        require("../assets/img/partner/34.png"),
        require("../assets/img/partner/35.png")
    ];
    let partnersList8 = [
        require("../assets/img/partner/36.png"),
        require("../assets/img/partner/37.png"),
        require("../assets/img/partner/38.png"),
        require("../assets/img/partner/39.png"),
        require("../assets/img/partner/40.png")
    ];

    let partners1 = partnersList1.map((value) =>
        <div>
            <img src = {value} alt={""}/>
        </div>
    )

    let partners2 = partnersList2.map((value) =>
        <div>
            <img src = {value} alt={""}/>
        </div>
    )

    let partners3 = partnersList3.map((value) =>
        <div>
            <img src = {value} alt={""}/>
        </div>
    )

    let partners4 = partnersList4.map((value) =>
        <div>
            <img src = {value} alt={""}/>
        </div>
    )

    let partners5 = partnersList5.map((value) =>
        <div>
            <img src = {value} alt={""}/>
        </div>
    )

    let partners6 = partnersList6.map((value) =>
        <div>
            <img src = {value} alt={""}/>
        </div>
    )

    let partners7 = partnersList7.map((value) =>
        <div>
            <img src = {value} alt={""}/>
        </div>
    )

    let partners8 = partnersList8.map((value) =>
        <div>
            <img src = {value} alt={""}/>
        </div>
    )

    const [isOpen, setList] = useState(true);
    const showPartners = () => {
        setList(isOpen => !isOpen);
    }

    useEffect(() => {

        $("header").removeClass("black");
        $("header .gnb img").css("filter", "none");
        showHistory($(".y-22"));
        $(".year-wrapper > div").on("click", function () {
            showHistory($(this));
        });
        slideHistory();
        KakaoMap();

        function showHistory(year) {
            $(".year-wrapper > div > h3").css("color", "#454666");
            year.children().css("color", "#fff");
            $(".history-bottom .list").css("opacity", "0");
            $(".history-bottom")
                .find("#" + year.attr("class"))
                .css("opacity", "1");
        }

        function slideHistory() {
            //상단 year index 좌우 slide
            $("#prev-btn").on("click", function () {
                $(".year-wrapper").css("transform", "none");
                $("#next-btn").css("display", "block");
                $(this).css("display", "none");
                // "#history-next".css("display", "none");
            });

            $("#next-btn").on("click", function () {
                $(".year-wrapper").css("transform", "translateX(-75%)");
                $("#prev-btn").css("display", "block");
                $(this).css("display", "none");
                // $("#history-next").css("display", "none");
            });
        }

        function KakaoMap() {
            const container = document.getElementById("map");
            const options = {
                center: new kakao.maps.LatLng(37.466992, 127.042983),
                level: 3,
            };
            const map = new kakao.maps.Map(container, options);

            const imageSrc = require("../assets/img/map-marker.png"),
                imageSize = new kakao.maps.Size(136, 44),
                imageOption = { offset: new kakao.maps.Point(20, 30) };

            const markerImage = new kakao.maps.MarkerImage(
                    imageSrc,
                    imageSize,
                    imageOption
                ),
                markerPosition = new kakao.maps.LatLng(37.466992, 127.042983); // 마커가 표시될 위치입니다

            const marker = new kakao.maps.Marker({
                position: markerPosition,
                image: markerImage, // 마커이미지 설정
            });
            marker.setMap(map);
        }

        $(".about-wrapper2 .title").each(function () {
            ScrollTrigger.create({
                trigger: ".about-wrapper2",
                start: "top center",
                end: "bottom 100px",
                onEnter: () => $(this).addClass("ani"),
                onLeave: () => $(this).removeClass("ani"),
                onEnterBack: () => $(this).addClass("ani"),
            });
        });
    },[])



    return (
        <div className="AboutPage">
            <div className="about-wrapper1 section" id="section1">
                <div className="sub-title">
                    <h3>Truly, Madly, Deeply for Data, ZETTASOFT</h3>
                </div>
                <div className="title">
                    <h1 className="first"><span>DATA INNOVATION</span></h1>
                    <div>
                        <h1 className="third"><span>BEST&nbsp;</span></h1>
                        <h1 className="second"><span>PARTNER</span></h1>
                    </div>
                </div>
                <div className="detail">
                    <p>
                        (주)제타소프트는 다양한 산업 분야의 4차 산업 플랫폼 구축을 위한 지속적인
                        연구 및 개발을 통해<br />
                        고객의 니즈를 반영하여 비즈니스 혁신을 지원하는 데이터 전문 IT 강소
                        기업입니다.
                    </p>
                </div>
                <img className="polygon" src={require(`../assets/img/about-polygon.png`)} alt={""}/>
            </div>
            <div className="about-wrapper3 section" id="section2">
                <div className="history-top">
                    <a className="history-prev" id="prev-btn" to={""}></a>
                    <a className="history-next" id="next-btn" to={""}></a>
                    <div className="year">
                        <div className="year-wrapper">
                            <div className="y-12"><h3>2012</h3></div>
                            <div className="y-15"><h3>2015</h3></div>
                            <div className="y-18"><h3>2018</h3></div>
                            <div className="y-19"><h3>2019</h3></div>
                        </div>
                        <div className="year-wrapper">
                            <div className="y-20"><h3>2020</h3></div>
                            <div className="y-21"><h3>2021</h3></div>
                            <div className="y-22"><h3>2022</h3></div>
                        </div>
                    </div>
                </div>
                <div className="history-bottom">
                    <h1>HISTORY</h1>
                    <div className="list" id="y-12">
                        <div><p>제타소프트 설립</p></div>
                    </div>
                    <div className="list" id="y-15">
                        <div><p>교통안전공단 정보시스템 고도화 사업</p></div>
                        <div><p>메트라이프생명보험 영업정보시스템 구축 사업</p></div>
                        <div><p>관세청 차세대 정보시스템 구축 사업</p></div>
                    </div>
                    <div className="list" id="y-18">
                        <div><p>한국은행 정보시스템 고도화 사업</p></div>
                        <div><p>시각화 배포/관리 솔루션 "SuperVisual" 개발/런칭</p></div>
                        <div><p>LS전선연구소 정보시스템 고도화 사업</p></div>
                        <div><p>금융결제원 빅데이터 플랫폼 구축 사업</p></div>
                        <div><p>롯데제과 LCIA 대시보드 Cognos 시각화 구축 사업</p></div>
                    </div>
                    <div className="list" id="y-19">
                        <div><p>메트라이프생명보험 CRM 분석 사업</p></div>
                        <div><p>신한카드 빅데이터 분석 플랫폼 포털 구축 사업</p></div>
                        <div><p>신한은행 빅데이터 분석 플랫폼 포털 구축 사업</p></div>
                        <div><p>대구시청 빅데이터 플랫폼 포털/시각화 구축 사업</p></div>
                        <div><p>한국데이터진흥원 데이터 안심구역 BI솔루션 구축</p></div>
                    </div>
                    <div className="list" id="y-20">
                        <div><p>근로복지공단 재정추계/ 요율예측 분석/시각화 구축</p></div>
                        <div><p>특허청 아이디어거래 플랫폼 구축 사업</p></div>
                        <div><p>빅데이터 분석 플랫폼 분석환경 솔루션 개발</p></div>
                        <div><p>아산병원 AI Model 자동화 시스템 구축 사업</p></div>
                        <div><p>모비노마 모비북 및 CRM 분석 대시보드 개발 사업</p></div>
                    </div>
                    <div className="list" id="y-21">
                        <div><p>데이터바우처지원사업 공급기업 선정</p></div>
                        <div><p>데이터바우처지원사업 데이터가공 사업 계약</p></div>
                        <div><p>국립중앙도서관 MIS 시스템 구축 사업</p></div>
                        <div><p>농협중앙회 빅데이터플랫폼 포털 구축 사업</p></div>
                        <div><p>BNK 부산경남은행 빅데이터플랫폼 구축 사업</p></div>
                    </div>
                    <div className="list" id="y-22">
                        <div><p>LX판토스 BDP 포털 구축</p></div>
                        <div><p>BNK 부산은행 GraphDB 시스템 구축 사업</p></div>
                        <div><p>LG전자 데이터포털 운영 안정화 사업</p></div>
                        <div><p>롯데타워몰 F&B 스마트테이블링 서비스 구축 사업</p></div>
                        <div><p>롯데타워몰 매장 분석 BI QlikSense 구축 사업</p></div>
                        <div><p>매트라이프생명보험 Qliksense 업그레이드 사업</p></div>
                        <div><p>신세계패션 BI Tableau 분석 시스템 구축 사업</p></div>
                    </div>
                    <Link className="next-btn" id="history-next" to={""}>
                        <img src={require(`../assets/img/ico-arrow-down-wt.png`)} alt={""}/>
                    </Link>
                </div>
            </div>
            <div className="about-wrapper6 section" id="section3">
                <h1>CORPORATE IDENTITY</h1>
                <div className="card-wrapper">
                    <div>
                        <img src={require(`../assets/img/vision-1.png`)} alt={""}/>
                        <p className="sub-title">CORE VALUE</p>
                        <h3>BRAVE<br />CHALLENGE</h3>
                        <p className="vision-sub">무한한 도전을<br />꿈꾸는 기업</p>
                    </div>
                    <div>
                        <img src={require(`../assets/img/vision-2.png`)} alt={""}/>
                        <p className="sub-title">CORE VALUE</p>
                        <h3>MUTUAL<br />PARTNERSHIP</h3>
                        <p className="vision-sub">
                            고객과의 신뢰를<br />
                            중시하는 기업
                        </p>
                    </div>
                    <div>
                        <img src={require(`../assets/img/vision-3.png`)} alt={""}/>
                        <p className="sub-title">CORE VALUE</p>
                        <h3>CONSTANT<br />INNOVATION</h3>
                        <p className="vision-sub">
                            지속적인 혁신을<br />
                            추구하는 기업
                        </p>
                    </div>
                    <div>
                        <img src={require(`../assets/img/vision-4.png`)} alt={""}/>
                        <p className="sub-title">CORE VALUE</p>
                        <h3>ACCOMPANIED<br />GROWTH</h3>
                        <p className="vision-sub">
                            고객과 제타의 모든 <br />구성원의 동반 성장을<br />
                            지향하는 기업
                        </p>
                    </div>
                </div>
            </div>
            <div className="about-wrapper7 section" id="section4">
                <div className="title">
                    <h1>고객사 및 파트너사</h1>
                    <h3>CUSTOMERS & PARTNERS</h3>
                </div>
                <div className="partners-box">
                    <div>
                        {partners1}
                    </div>
                    <div>
                        {partners2}
                    </div>
                    <div>
                        {partners3}
                    </div>
                    <div className="extra" style={isOpen ? {display:"none"}:{display:"flex"}}>
                        {partners4}
                    </div>
                    <div className="extra" style={isOpen ? {display:"none"}:{display:"flex"}}>
                        {partners5}
                    </div>
                    <div className="extra" style={isOpen ? {display:"none"}:{display:"flex"}}>
                        {partners6}
                    </div>
                    <div className="extra" style={isOpen ? {display:"none"}:{display:"flex"}}>
                        {partners7}
                    </div>
                    <div className="extra" style={isOpen ? {display:"none"}:{display:"flex"}}>
                        {partners8}
                    </div>
                </div>
                <div className={isOpen ? "view-more" : "view-more min section"} onClick={()=>showPartners()}></div>
            </div>
            <div className="about-wrapper8 section" id="section5">
                <div className="title">
                    <h1>CONTACT US</h1>
                </div>
                <div className="info">
                    <div>
                        <img src={require(`../assets/img/ico-phone.png`)} alt={""}/>
                        <p>02-575-3088</p>
                    </div>
                    <div>
                        <img src={require(`../assets/img/ico-email.png`)} alt={""}/>
                        <p>contact@zettasoft.co.kr</p>
                    </div>
                    <div>
                        <img src={require(`../assets/img/ico-loc.png`)} alt={""}/>
                        <p>06788 서울시 서초구 강남대로 6길 28-12 새롬빌딩 2층</p>
                    </div>
                </div>
                <div className="map-bg">
                    <div id="map"></div>
                </div>
            </div>
        </div>
    )
}

export default AboutPage;
