import TopHeader from "./TopHeader";
import FixFooter from "./FixFooter";
import React from "react";

const Layout = (props) => {
    return (
        <div>
            <TopHeader />
            <main>
                {props.children}
            </main>
            <FixFooter />
        </div>
    )
}

export default Layout