import {useEffect, useRef} from "react";
import { useLocation } from "react-router-dom";
import $ from "jquery";

export default function ScrollToTop () {

    //페이지 주소 변경 또는 새로고침시 최상단으로 이동
    const {pathname} = useLocation();
    const mHtml =$("html");
    let page = useRef(1);

    /*useEffect(() => {
        window.scrollTo(0,0);
    }, [pathname]);*/

    useEffect(() => {
        mHtml.animate({scrollTop : 0},10);

        /*if($(".map-bg").hover(function (){
            console.log("test test")
        },function (){
            console.log("1111111")
        }))*/
        $(window).on("wheel", function(e) {
            if(mHtml.is(":animated")) return;
            if(e.originalEvent.toElement.localName === "svg") return;
            if(e.originalEvent.toElement.unselectable === "on") return;
            if(e.originalEvent.deltaY > 0) {
                if(page.current === $('.section').length+1) {
                }else{
                    page.current += 1;
                }
            } else if(e.originalEvent.deltaY < 0) {
                if(page.current === 1) {
                } else{
                    page.current -= 1;
                }
            }

            let posTop =(page.current-1) * $(window).height();
            mHtml.animate({scrollTop : posTop}, 1000);
        })
        return () => {page.current = 1};
    },[pathname,mHtml]);

    return null;
}