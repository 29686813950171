import React, {useEffect} from "react";
import $ from "jquery";

const { kakao } = window;

function ContactPage() {

    useEffect(() => {
        $("header").removeClass("black");
        $("header .gnb img").css("filter", "none");

        KakaoMap();

        function KakaoMap() {
            const container = document.getElementById("map");
            const options = {
                center: new kakao.maps.LatLng(37.466992, 127.042983),
                level: 3,
            };
            const map = new kakao.maps.Map(container, options);

            const imageSrc = require("../assets/img/map-marker.png"),
                imageSize = new kakao.maps.Size(136, 44),
                imageOption = { offset: new kakao.maps.Point(20, 30) };

            const markerImage = new kakao.maps.MarkerImage(
                    imageSrc,
                    imageSize,
                    imageOption
                ),
                markerPosition = new kakao.maps.LatLng(37.466992, 127.042983); // 마커가 표시될 위치입니다

            const marker = new kakao.maps.Marker({
                position: markerPosition,
                image: markerImage, // 마커이미지 설정
            });
            marker.setMap(map);
        }
    }, []);
    return (
        <div>
            <div className="contact-wrapper section" id="section1">
                <div className="title">
                    <h1>CONTACT US</h1>
                </div>
                <div className="contact-box">
                    <div>
                        <img src={require(`../assets/img/ico-phone.png`)} alt={""}/>
                        <h4>02 575 3088</h4>
                    </div>
                    <div>
                        <img src={require(`../assets/img/ico-loc.png`)} alt={""}/>
                        <h4>서울시 서초구 강남대로 6길 28-12 새롬빌딩 2층(06788)</h4>
                    </div>
                    <div>
                        <img src={require(`../assets/img/ico-email.png`)} alt={""}/>
                        <h4>contact@zettasoft.co.kr</h4>
                    </div>
                </div>
                <div className="map-bg">
                    <div id="map"></div>
                </div>
            </div>
        </div>
    )
}

export default ContactPage;