import React, {useEffect} from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {Link, useLocation} from "react-router-dom";
import $ from "jquery";

function CareersPage() {
    const {pathname} = useLocation();
    useEffect(() => {
        $("header").removeClass("black");
        $("header .gnb img").css("filter", "none");

        $(".benefits-list ul li").hover(function () {
            $(".benefits-list ul li").removeClass("on");
            $(this).addClass("on");
            $(this).css("border", "none");
            $(this).prev().css("border", "none");
        });

        $(".benefits-list ul li").on("mouseleave", function () {
            $(".benefits-list ul li").css("border-bottom", "1px solid #ccc");
        });
    },[pathname]);

    useEffect(() => {
        $(".career-wrapper2").each(function () {
            ScrollTrigger.create({
                trigger: ".career-wrapper2",
                start: "top center",
                end: "+=1000",
                onEnter: () => $(this).addClass("ani"),
                onLeave: () => $(this).removeClass("ani"),
                onEnterBack: () => $(this).addClass("ani"),
                // onLeaveBack: () => $(this).removeClass("ani"),
            });
        });

        /*$(".career-wrapper2").each(function () {
            ScrollTrigger.create({
                trigger: ".career-wrapper2",
                start: "top top",
                onEnter: () => $("header").addClass("black"),
                onLeave: () => $("header").removeClass("black"),
                onEnterBack: () => $("header").addClass("black"),
                onLeaveBack: () => $("header").removeClass("black"),
            });
        });*/

        $(".career-wrapper3").each(function () {
            ScrollTrigger.create({
                trigger: ".career-wrapper2",
                start: "bottom center",
                end: "+=1000",
                onEnter: () => $(this).addClass("ani"),
                onLeave: () => $(this).removeClass("ani"),
                onEnterBack: () => $(this).addClass("ani"),
                // onLeaveBack: () => $(this).removeClass("ani"),
            });
        });

        $(".career-wrapper4").each(function () {
            ScrollTrigger.create({
                trigger: ".career-wrapper4",
                start: "top center",
                end: "bottom bottom",
                onEnter: () => $(this).addClass("ani"),
            });
        });
    },[pathname])

    return (
        <div>
            <div className="career-wrapper1 section" id="section1">
                <div className="filter"></div>
                <div className="title">
                    <h1>
                        제타소프트에서<br/>
                        함께 성장해나갈<br/>[ Z-STAR ] 가 되어주세요
                    </h1>
                    <h3>
                        "Z-Star"는 소통과 배려, 화목을 중시하는 제타소프트 내 모든 구성원이
                        <br/>
                        <span>별처럼 반짝반짝 빛나는 소중한 인재</span>임을 의미합니다.
                    </h3>
                </div>
            </div>
            <div className="career-wrapper2 section" id="section2">
                <div className="title">
                    <h1 className="benefits">
                        <span style={{"--i":1}}>B</span><span style={{"--i":2}}>E</span>
                        <span style={{"--i":3}}>N</span><span style={{"--i":4}}>E</span>
                        <span style={{"--i":5}}>F</span><span style={{"--i":6}}>I</span>
                        <span style={{"--i":7}}>T</span><span style={{"--i":8}}>S</span>
                    </h1>
                    <h1><span>ENJOYED</span></h1>
                    <h1><span>BY ALL</span></h1>
                </div>
                <div className="benefits-list">
                    <ul>
                        <li>
                            <div className="chk"></div>
                            <h3>명절 상여금</h3>
                            <p>2대 명절 설과 추석을 명절 상여금과 함께 즐겨요!</p>
                        </li>
                        <li>
                            <div className="chk"></div>
                            <h3>도서구입비 지원</h3>
                            <p>업무 관련 도서 구입비를 지원드려요</p>
                        </li>
                        <li>
                            <div className="chk"></div>
                            <h3>성과 보상 지원</h3>
                            <p>프로젝트를 잘 끝마친 임직원에게는 인센티브를 지원해요</p>
                        </li>
                        <li>
                            <div className="chk"></div>
                            <h3>리프레시 휴가</h3>
                            <p>휴식이 필요한 임직원에게 자유로운 연월차를 제공해요</p>
                        </li>
                        <li>
                            <div className="chk"></div>
                            <h3>장기 근속 포상</h3>
                            <p>
                                5년, 10년, 15년 20년 등<br/>
                                장기 근속 임직원에게 표창과 포상금을 제공해요
                            </p>
                        </li>
                        <li>
                            <div className="chk"></div>
                            <h3>경조사비 지원</h3>
                            <p>누구나 겪게 되는 크고 작은 경조사비를 지원해요</p>
                        </li>
                        <li>
                            <div className="chk"></div>
                            <h3>자기계발비 지원</h3>
                            <p>개인 운동비 및 업무 향상을 위한 학원 수강 등 지원해요</p>
                        </li>
                    </ul>
                </div>
                <img className="polygon" src={require(`../assets/img/career-polygon1.png`)} alt={""}/>
            </div>
            <div className="career-wrapper3 section" id="section3">
                <div className="title">
                    <h1>채용 절차</h1>
                    <h3>EMPLOYMENT PROCESS</h3>
                </div>
                <div className="steps-wrapper">
                    <div>
                        <img className="line" src={require(`../assets/img/steps-line.png`)} alt={""}/>
                        <h3>01</h3>
                        <h4>서류전형 <span>(hr@zettasoft.co.kr)</span></h4>
                        <p>
                            이메일을 통한 이력서 접수,<br/>
                            자유 양식의 이력서 및 포트폴리오(선택) 제출
                        </p>
                        <img src={require(`../assets/img/img-step1.png`)} alt={""}/>
                    </div>
                    <div>
                        <img className="line" src={require(`../assets/img/steps-line.png`)} alt={""}/>
                        <h3>02</h3>
                        <h4>대면 면접전형</h4>
                        <p>조직과 직무, 프로젝트 일정에 적합한 인재 선발</p>
                        <img src={require(`../assets/img/img-step2.png`)} alt={""}/>
                    </div>
                    <div>
                        <img className="line" src={require('../assets/img/steps-line.png')} alt={""}/>
                        <h3>03</h3>
                        <h4>합격여부 개별 통지</h4>
                        <p>
                            개별 유선통화 및 문자(MMS) 를 통한<br/>
                            면접 결과 안내
                        </p>
                        <img src={require('../assets/img/img-step3.png')} alt={""}/>
                    </div>
                </div>
            </div>
            <div className="career-wrapper4 section" id="section4">
                <div className="title">
                    <h3>ZETTASOFT GROWS WITH YOU</h3>
                    <h1>제타소프트와 함께<br/>성장해갈 <span>인재</span>를 기다립니다</h1>
                </div>
                <div className="email">
                    <img src={require('../assets/img/ico-email.png')} alt={""}/>
                    <p>hr@zettasoft.co.kr<span>(이력서 접수 중)</span></p>
                </div>
                <Link className="map-link" to="https://naver.me/5grna180" target="_blank">오시는길</Link>
            </div>
        </div>
    )
}

export default CareersPage;