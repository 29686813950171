import React, {useEffect} from "react";
import icoPd1 from '../assets/img/ico-pd1.png';
import icoPd2 from '../assets/img/ico-pd2.png';
import icoPd3 from '../assets/img/ico-pd3.png';
import icoDemo from '../assets/img/ico-demo.png';
import icoTrial from '../assets/img/ico-trial.png';
import {Link} from "react-router-dom";
import $ from "jquery";

function ProductPage() {

    useEffect(() => {
        $("header").removeClass("black");
        $("header .gnb img").css("filter", "none");

        $(".category").on("mouseover", function () {
            $(".category").removeClass("on");
            $(".category .txt-wrapper").css("opacity", "0");
            $(this).addClass("on");
            $(this).children(".txt-wrapper").css("opacity", "1");
            $(this).prevAll().css("transform", "translateX(-60%)");
        });

        $(".category").on("mouseleave", function () {
            $(this).removeClass("on");
            $(this).prevAll().css("transform", "translateX(0)");
            $(this).children(".txt-wrapper").css("opacity", "0");

            $(".category.num-1").addClass("on");
            $(".category.num-1").children(".txt-wrapper").css("opacity", "1");
        });
    }, []);
    return (
        <div>
            <div className="product-wrapper1 section" id="section1">
                <div className="title">
                    <h3><span>상상하는 모든 기술의 실현!</span></h3>
                    <h1>
                        제타소프트만의 <span>특별한</span><br/>
                        기술을 <span>경험</span>해보세요
                    </h1>
                </div>
                <div className="scroll-bottom">
                    <img src={require(`../assets/img/ico-mouse.png`)} alt={""}/>
                    <p>SCROLL DOWN</p>
                </div>
            </div>
            <div className="main-wrapper4">
                <div id="product-1" className="category num-1 on">
                    <div className="txt-wrapper">
                        <p>PRODUCT</p>
                        <div className="title">
                            <img src={icoPd1} alt={""}/>
                            <div className="line"></div>
                            <div className="name">
                                <h1>Super<br/>WorkBench<br/>3.0</h1>
                            </div>
                        </div>
                        <div className="product-detail">
                            <h3>
                                빅데이터 분석 환경과 데이터 포털 시스템을<br/>ALL-IN-ONE
                                제공합니다.
                            </h3>
                            <p>
                                데이터 분석 환경, 시각화, 형상관리 등 다양한 솔루션을<br/>
                                포털 시스템에 연계하여 복잡한 빅데이터 분석을<br/>
                                쉽게 수행할 수 있도록 사용자 맞춤 기능을 컨설팅 및 제공합니다.
                            </p>
                        </div>
                        <div className="product-link">
                            <div>
                                <img src={require(`../assets/img/ico-download.png`)} alt={""}/>
                                <a
                                    href="../assets/document/swb-doc.pdf"
                                    download="swb_introduction.pdf"
                                    target="_self"
                                >INTRODUCTION
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="icon">
                        <img src={icoPd1} alt={""}/>
                    </div>
                </div>
                <div className="category num-2 section" id="product-2">
                    <div className="txt-wrapper">
                        <p>BI PLATFORM</p>
                        <div className="title">
                            <img src={icoPd2} alt={""}/>
                            <div className="line"></div>
                            <div className="name">
                                <h1>QlikSense</h1>
                            </div>
                        </div>
                        <div className="product-detail">
                            <h3>
                                차세대 분석을 위한<br/>
                                데이터 시각화 솔루션을 제공합니다.
                            </h3>
                            <p>
                                특허 받은 인메모리 기반 연관 분석 엔진, 정교한 AI 및 고성능 클라우드
                                플랫폼<br/>
                                등과 함께 더 나은 경영 전략을 세울 수 있도록 탄탄한 시각화
                                대시보드를 지원합니다.
                            </p>
                        </div>
                        <div className="product-link">
                            <div>
                                <img src={icoDemo} alt={""}/>
                                <Link to="https://demos.qlik.com/qliksense" target="_blank"
                                >SEE DEMO</Link>
                            </div>
                            <div>
                                <img src={icoTrial} alt={""}/>
                                <Link
                                    to="https://www.qlik.com/us/trial/qlik-sense-business?_ga=2.107564802.1861271613.1676009456-372241311.1676009456"
                                    target="_blank"
                                >FREE TRIAL</Link>
                            </div>
                        </div>
                    </div>

                    <div className="icon">
                        <img src={icoPd2} alt={""}/>
                    </div>
                </div>
                <div className="category num-3 section" id="product-3">
                    <div className="txt-wrapper">
                        <p>BI PLATFORM</p>
                        <div className="title">
                            <img src={icoPd3} alt={""}/>
                            <div className="line"></div>
                            <div className="name">
                                <h1>Tableau</h1>
                            </div>
                        </div>
                        <div className="product-detail">
                            <h3>
                                성공적인 비즈니스 예측,<br/>의사 결정을 위한 분석을 제공합니다
                            </h3>
                            <p>
                                세계적 기업들이 애용하는 최신 분석 플랫폼인<br/>
                                Tableau의 중강된 분석 혁신 기술을 활용하여 성공적인<br/>
                                경영 전략과 비즈니스 예측을 할 수 있는 시각화 대시보드를 지원합니다.
                            </p>
                        </div>
                        <div className="product-link">
                            <div>
                                <img src={icoDemo} alt={""}/>
                                <Link
                                    to="https://www.tableau.com/data-insights/dashboard-showcase"
                                    target="_blank"
                                >SEE DEMO</Link
                                >
                            </div>
                            <div>
                                <img src={icoTrial} alt={""}/>
                                <Link to="https://www.tableau.com/products/trial" target="_blank"
                                >FREE TRIAL</Link
                                >
                            </div>
                        </div>
                    </div>
                    <div className="icon">
                        <img src={icoPd3} alt={""}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductPage;