import zettaLogo from '../../assets/img/zetta-logo.svg'
import icoMenu from '../../assets/img/ico-menu.png'
import arrowR from '../../assets/img/arrow-r.png'
import {Link, useLocation} from "react-router-dom";
import {useEffect} from "react";

export default function TopHeader (){
    //const {pathname} = useLocation();

    //스크롤 사용시 헤더 활성/비활성화
    /*useEffect(() => {
        const header = document.querySelector("header");

        const headerMoving = function(direction){
            //console.log(pathname)
            if(direction === "up"){
                if(pathname === "/business"){
                    header.className = 'black';
                }else{
                    header.className = " ";
                }
            } else if (direction === "down") {
                if(pathname === "/business"){
                    header.className = 'scrollDown black';
                }else{
                    header.className = 'scrollDown';
                }
            }
        };

        let preScrollTop = 0;
        document.addEventListener("scroll", function (){
            let nextScrollTop = window.pageYOffset || 0;
            if(nextScrollTop > preScrollTop) {
                headerMoving("down");
            } else if(nextScrollTop < preScrollTop) {
                headerMoving("up");
            }
            preScrollTop = nextScrollTop;
        });
    });*/

    return (
        <div>
            <header>
                <div className="gnb">
                    <Link to="/" className="logo">
                        <img src={zettaLogo} alt={""}/>
                    </Link>
                </div>
                <div className="gnb-menu">
                    <Link to="/about" className="about"><h3>ABOUT US</h3></Link>
                    <Link to="/product" className="product"><h3>PRODUCT&PLATFORM</h3></Link>
                    <Link to="/careers" className="careers"><h3>RECRUIT</h3></Link>
                    <Link to="/contact" className="contact"><h3>CONTACT</h3></Link>
                </div>
                <div className="header-right">
                    <div className="mobile-menu">
                        <img src={icoMenu} alt={""}/>
                    </div>
                    <Link to="/business" className="business">
                        <h3>GET IN TOUCH</h3>
                        <img src={arrowR} alt={""}/>
                    </Link>
                </div>
            </header>
        </div>
    )
}