import footerLogo from '../../assets/img/footer-logo.svg'
import footerIco1 from '../../assets/img/footer-ico-1.png'
import footerIco2 from '../../assets/img/footer-ico-2.png'
import footerIco3 from '../../assets/img/footer-ico-3.png'

const FixFooter = () => {
    return (
        <div>
            <div className="footer">
                <div className="logo">
                    <img src={footerLogo} alt={""}/>
                </div>
                <div className="content">
                    <div>
                        <h3>주식회사 제타소프트</h3>
                        <div>
                            <img src={footerIco1} alt={""}/>
                            <p>02 575 3088</p>
                        </div>
                        <div>
                            <img src={footerIco2} alt={""}/>
                            <p>contact@zettasoft.co.kr</p>
                        </div>
                        <div>
                            <img src={footerIco3} alt={""}/>
                            <p>06788 서울특별시 서초구 강남대로6길 28-12 새롬빌딩 2층</p>
                        </div>
                    </div>
                    <div>
                        <p className="copyright">COPYRIGHT @2012 ZETTASOFT.ALL RIGHTS RESERVED.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FixFooter;